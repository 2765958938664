<template>
  <v-main>
    <v-container fluid>
      <v-row
        justify="center"
        style="min-height: 100vh;"
        :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-top: 5vh;'"
      >
        <v-col cols="12" md="5" lg="4" v-if="!success">
          <v-row justify="center">
            <v-col
              cols="11"
              md="10"
              class="text-center pa-0"
              align-self="center"
              v-if="template.var.logo_only"
            >
              <v-img
                v-if="!template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo"
                width="75%"
                class="mx-auto"
              ></v-img>
              <v-img
                v-if="template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo_light"
                width="75%"
                class="mx-auto"
              ></v-img>
            </v-col>
            <v-col
              cols="3"
              md="2"
              class="text-center pa-0"
              align-self="center"
              v-if="!template.var.logo_only"
            >
              <v-img
                class="img-fluid"
                :src="'/img/' + template.var.logo_light"
              ></v-img>
            </v-col>
            <v-col cols="10" class="text-center mt-5">
              <h2 :style="`color: ${template.colors.inline_primary_text}`">
                PASSWORT ZURÜCKSETZEN
              </h2>
              <p
                :style="`color: ${template.colors.inline_background_text}`"
                v-if="id && verify"
              >
                Bitte lege ein neues Passwort für deinen Account fest.
              </p>
              <p
                :style="`color: ${template.colors.inline_background_text}`"
                v-if="!id || !verify"
              >
                Der Link zum Zurücksetzen deines Passworts scheint fehlerhaft zu
                sein.
              </p>
            </v-col>
            <v-col cols="10" v-if="id && verify">
              <v-text-field
                type="password"
                v-model="form.password"
                filled
                rounded
                dark
                label="Neues Passwort"
              ></v-text-field>
              <v-text-field
                type="password"
                v-model="form.passwordconfirm"
                filled
                rounded
                dark
                label="Neues Passwort bestätigen"
              ></v-text-field>
              <v-btn
                block
                rounded
                dark
                large
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                :loading="loader"
                @click="submit()"
              >
                <v-icon class="mr-2">mdi-account-lock</v-icon>
                Neues Passwort speichern
              </v-btn>
              <v-btn
                block
                dark
                outlined
                rounded
                large
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                class="my-4"
                :disabled="loader"
                to="/login"
              >
                Zurück zum Login
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" lg="3" v-if="success">
          <v-row justify="center">
            <v-col
              cols="11"
              md="10"
              class="text-center pa-0"
              align-self="center"
              v-if="template.var.logo_only"
            >
              <v-img
                v-if="!template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo"
                width="75%"
                class="mx-auto"
              ></v-img>
              <v-img
                v-if="template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo_light"
                width="75%"
                class="mx-auto"
              ></v-img>
            </v-col>
            <v-col
              cols="3"
              md="2"
              class="text-center pa-0"
              align-self="center"
              v-if="!template.var.logo_only"
            >
              <v-img
                class="img-fluid"
                :src="'/img/' + template.var.logo_light"
              ></v-img>
            </v-col>
            <v-col cols="10" class="text-center">
              <h2 class="white--text">PASSWORT ERFOLGREICH GEÄNDERT</h2>
              <p class="white--text">
                Dein Passwort wurde erfolgreich geändert und du kannst dich
                damit nun einloggen.
              </p>
              <v-btn
                block
                dark
                outlined
                rounded
                large
                class="my-4"
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                :loading="loader"
                to="/login"
              >
                Zum Login
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      :color="template.colors.primary"
      :style="`color: ${template.colors.inline_primary_text};`"
      :dark="!template.light"
      bottom
      v-model="error"
    >
      {{ errortext }}
    </v-snackbar>
  </v-main>
</template>

<script>
//import firebase from '../../firebaseConfig'
import store from '../../store'
import router from '../../routes'
import logout from '../../auth/auth'
import { mapGetters } from 'vuex'

export default {
  name: 'Neues Passwort speichern',
  data() {
    return {
      show: false,
      form: {
        passwordconfirm: '',
        password: '',
      },
      id: '',
      verify: '',
      success: false,
      loader: false,
      error: false,
      errortext: '',
    }
  },
  computed: {
    ...mapGetters({
      template: 'template',
    }),
  },
  created() {
    if (this.getQueryVariable('id') && this.getQueryVariable('verify')) {
      this.id = this.getQueryVariable('id')
      this.verify = this.getQueryVariable('verify')
    }
  },
  methods: {
    submit() {
      if (this.form.password == this.form.passwordconfirm) {
        this.loader = true
        this.error = null

        var functions = firebase.app().functions('europe-west3')
        var resetPW = functions.httpsCallable('resetPassword')
        resetPW({
          id: this.id,
          verify: this.verify,
          newpassword: this.form.password,
          newpasswordconfirm: this.form.passwordconfirm,
        })
          .then((result) => {
            if (result.data.success) {
              this.success = true
            } else {
              this.error = true
              this.errortext = result.data.message
            }
            this.loader = false
          })
          .catch((error) => {
            this.creating = false
            this.error = true
            this.errortext = error
            this.loader = false
          })
      }
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return false
    },
  },
}
</script>
